import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private _authService: AuthService,
      ) { }


  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles = route.data.allowedRoles as Array<string>;
    return this._authService.isAuthenticated$.pipe(
      map(isAuth => {
        const userRoles = this._authService.currentUser.roleNames;
          if (!isAuth) {
            this.router.navigate(['/auth/login'])
            return false
          } else {
            if (userRoles && userRoles.some(role => allowedRoles.includes(role))) {
              return true; // User has at least one allowed role
            } else {
              if (userRoles.includes('supplies')) {
                this.router.navigate(['equipament'])
              }
              else if (userRoles.includes('hr')) {
                this.router.navigate(['encarregados'])
              }
              else if (userRoles.includes('shipping')) {
                this.router.navigate(['equipament'])
              }
              return false;
            }
          }
        }),
      )
  }
  
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  
}
