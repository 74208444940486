import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'app-sequencial-date-filter',
  templateUrl: './sequencial-date-filter.component.html',
  styleUrls: ['./sequencial-date-filter.component.scss']
})
export class SequencialDateFilterComponent implements OnInit {

  @Output()
  dateChanged = new EventEmitter();

  @Input()
  initialDate: Date

  private _debouncedUpdate: any;
  private _currentDate: Date
  
  public get currentDate() : Date {
    return this._currentDate
  }

  public set currentDate(v : Date) {
    this._currentDate = v;
    this._debouncedUpdate()
  }
  
  constructor() { 
    this._debouncedUpdate = _.debounce(this.emitChangedDate, 350);
  }

  ngOnInit() {
    this.currentDate = this.initialDate || new Date()
  }

  private emitChangedDate() {
    this.dateChanged.emit(this.currentDate)
  }

  previousDate() {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() -1)
  }

  nextDate() {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() +1)
  }
}
