import { Allocation } from 'app/entities';
import { Decodable } from './_decodable';
import { Permission } from './permission';
import { Reason } from './reason';

class MachineOperator extends Decodable {
    STATUS_AVAILABLE = 5
    STATUS_UNAVAILABLE = 6
    STATUS_LOCATED = 7;
    STATUS_TERMINATED = 8;
    STATUS_TIMEOFF = 9;

    id: number
    name: string
    status: number
    statusColor: string
    statusFormatted: string
    statusObservation: string
    isLocatedClass: string
    isLocatedFormatted: string
    email: string
    state: string
    city: string
    maritalStatus: string
    maritalStatusFormatted: string
    phoneNumber: string
    isLocated: boolean
    beginning: Date
    end: Date
    timeoffBeginning: Date
    timeoffEnd: Date

    allocations: Allocation[]
    activeAllocations: Allocation[]
    reasons: Reason[]

    createdAt: Date
    updatedAt: Date

    protected _map = {
        allocations: (e: any) => Allocation.parse(e),
        activeAllocations: (e: any) => Allocation.parse(e),
        reasons: (e: any) => Reason.parse(e),
        beginning: (e: any) => this.parseDate(e),
        end: (e: any) => this.parseDate(e),
        timeoffBeginning: (e: any) => this.parseDate(e),
        timeoffEnd: (e: any) => this.parseDate(e),
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
    }
}

export {
    MachineOperator
}