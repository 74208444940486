import { Comment } from 'app/entities';
import { User } from './user';
import { Decodable } from './_decodable';

class Call extends Decodable {
    STATUS_PENDING = 4
    STATUS_DONE = 5

    id: number
    status: number
    description: string
    TimeElapsed: string
    accompanimentId: number
    statusClass: string
    statusFormatted: string

    comments: Comment[]
    user: User

    updatedAt: Date
    createdAt: Date
    deletedAt: Date

    protected _map = {
        comments: e => Comment.parse(e),
        user: e => User.parse(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Call
}