import { EncarregadoVehicleHistory } from 'app/entities';
import { Vehicle } from 'app/entities';
import { map } from 'rxjs/operators';
import { EncarregadoHistory } from 'app/entities/encarregadoHistory';
import { Client, Accompaniment } from 'app/entities';
import { Decodable } from './_decodable';
import { Permission } from './permission';
import { Reason } from './reason';

class Encarregado extends Decodable {
    STATUS_AVAILABLE = 5
    STATUS_UNAVAILABLE = 6
    STATUS_ALOCATED = 7
    STATUS_TERMINATED = 8
    STATUS_TIMEOFF = 9
    STATUS_BLOQUEADO = 2
    STATUS_ATIVO = 0
    STATUS_AGUARDANDO_ATIVACAO = 3

    id: number
    name: string
    status: number
    statusColor: string
    statusFormatted: string
    statusPed: number
    statusPedColor: string
    statusPedFormatted: string
    statusObservation: string
    email: string
    city: string
    state: string
    maritalStatus: string
    currentVehicle: string
    additionalInfo: string
    phoneNumber: string
    beginning: Date
    end: Date
    timeoffBeginning: Date
    timeoffEnd: Date

    currentClientes: Client[]
    accompanimentHistory: Accompaniment[]
    vehicle: Vehicle
    vehicleHistory: EncarregadoVehicleHistory[]
    reasons: Reason[]
    createdAt: Date
    updatedAt: Date

    protected _map = {
        currentClientes: (e: any) => Client.parse(e),
        vehicleHistory: (e: any) => EncarregadoVehicleHistory.parse(e),
        accompanimentHistory: (e: any) => Accompaniment.parse(e),
        vehicle: (e: any) => Vehicle.parse(e),
        reasons: (e: any) => Reason.parse(e),
        beginning: (e: any) => this.parseDate(e),
        end: (e: any) => this.parseDate(e),
        timeoffBeginning: (e: any) => this.parseDate(e),
        timeoffEnd: (e: any) => this.parseDate(e),
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
    }
}

export {
    Encarregado
}