import { Decodable } from './_decodable';
import { Permission } from './permission';
import { Revision } from './revision';

class Cycle extends Decodable {
    id: number
    name: string
    revisions: Revision[]

    beginning: Date
    end: Date
    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
        beginning: (e: any) => this.parseDate(e),
        end: (e: any) => this.parseDate(e),
        revisions: (e: any) => Revision.parse(e),
    }
}

export {
    Cycle
}