import { Decodable } from './_decodable';

const actions = ['view', 'edit', 'create', 'delete', 'cancel', 'confirm', 'export', 'refund']
class Permission extends Decodable {
    id: number
    name: string
    nameFormatted: string
    module: string

    selected: boolean

    public get action() : string {
        const words = this.name.split(" ");
        if(words.length > 1 && actions.includes(words[0])) {
            return words[0]
        }
        return this.name
    }
    
}

export {
    Permission
}