import { Decodable } from './_decodable';
import { Client } from './client';
import { Cycle } from '.';
import { Product } from './product';

class Goal extends Decodable {
    id: number
    month: number
    quarter: number
    tons: number
    tonsReais: number
    cycleId: number
    accompanimentId: number

    cliente: Client
    cycle: Cycle
    product: Product
    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
        client: (e: any) => Client.parse(e),
        cycle: (e: any) => Cycle.parse(e),
        product: (e: any) => Product.parse(e),
    }
}

export {
    Goal
}