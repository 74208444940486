import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    HostBinding,
    ViewEncapsulation,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";

import { navigation } from "app/navigation/navigation";
import { AuthService } from "./services/auth.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    isAuthenticated$: Observable<boolean>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _platform: Platform,
        private _authService: AuthService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        this.matIconRegistry.addSvgIcon(
            `cicloverdelogo`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/images/logos/cicloverde_logo_white.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `dolly`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/dolly.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `dropbox`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/dropbox.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `pie_chart`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/pie_chart.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `lab_research`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/lab_research.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `drag`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/drag.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `package-variant`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/package-variant.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `package-variant-closed`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/package-variant-closed.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `truck-check`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/truck-check.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `truck-fast`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/truck-fast.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `truck-delivery`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/truck-delivery.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `truck-error`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/truck-error.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `car_crash_black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/car_crash_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `map-clock-outline`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/map-clock-outline.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `basket-fill`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/basket-fill.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `schedule`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/schedule.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `arrow-split-vertical`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/arrow-split-vertical.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `cart-arrow-down`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/cart-arrow-down.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `cart-outline`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/cart-outline.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `credit-card-plus-outline`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/credit-card-plus-outline.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `account_details`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/account_details.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `printer-settings`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/printer-settings.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `cash-multiple`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/cash-multiple.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `science`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/science.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `ifb_logo`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/ifb_logo.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `client_icon_2`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/client_icon_2.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `store-outline`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/store-outline.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `ticket-percent-outline`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/ticket-percent-outline.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `sign-real-estate`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/sign-real-estate.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `account-key-outline`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/account-key-outline.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `badge-account-outline`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/badge-account-outline.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `message-text-outline`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/message-text-outline.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `coupon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/coupon.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `credit-card`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/credit-card.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `card-machine`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/card-machine.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `pix`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/pix.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `cancel_black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/cancel_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `hourglass_top_black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/hourglass_top_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `local_shipping_black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/local_shipping_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `local_shipping_current`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/local_shipping_current_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `map_black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/map_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `paid_black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/paid_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `paid_warning`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/paid_warning_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `receipt_long_black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/receipt_long_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `view_in_ar_black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/view_in_ar_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `account_balance_black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/account_balance_black.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `logo_atacalog`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/logo_atacalog.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `platform_logo`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/platform_logo.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `images`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/images.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `allocation_icon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/allocation_icon_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `attach_money`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/attach_money.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `cycle-icon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/update_white_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `organic-material-icon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/grass_white24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `product-icon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/warehouse_white_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `delivery-icon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/local_shipping_white_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `equipament-icon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/agriculture_white_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `accompaniment-icon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/fact_check_white_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `chat-black`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/chat_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `delete`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/delete_outline_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `edit`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/edit_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `status`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/toggle_on_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `home_repair_current`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/home_repair_service_current_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `planning_white`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/planning_white_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `processing_white`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/processing_white_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `production_plan_white`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/checklist_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `trending_down`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/trending_down_red_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `trending_up`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/trending_up_green_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `lock_red`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/lock_red_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `notifications`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/notifications_active_black_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `account_circle_24dp`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/account_circle_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `no_account_24dp`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/no_accounts_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `engineering`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/engineering_current_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `badge`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/badge_current_24dp.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `full_screen`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/full_screen.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `client_icon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/client_icon.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `person`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/person.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `location_off`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/location_off.svg`
            )
        );

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.isAuthenticated$ = _authService.isAuthenticated$;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._authService.currentUser$.subscribe((user) => {
            this._fuseNavigationService.setCurrentPermissions(
                user.permissionNames
            );
            this._fuseNavigationService.setCurrentRoles(user.roleNames);
        });

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (
                        className.startsWith("theme-") &&
                        !className.startsWith("theme-dev")
                    ) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
