import { Decodable } from './_decodable';
import { Permission } from './permission';

class Role extends Decodable {
    id: number
    name: string
    nameFormatted: string
    module: string

    permissions: Permission[]

    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
        permissions: (e: any) => Permission.parse(e)
    }
}

export {
    Role
}