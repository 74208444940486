import * as moment from 'moment';
import { Accompaniment } from './accompaniment';
import { Allocation } from './allocation';
import { Contract } from './contract';
import { DateFormatted } from './date-formatted';
import { Encarregado } from './encarregado';
import { GanttData } from './ganttData';
import { Goal } from './goal';
import { Decodable } from './_decodable';
class Client extends Decodable implements GanttData{
    PROSPECTUS = 'prospecto';
    CLIENT = 'cliente';
    
    id: number
    name: string
    type: string
    latitude: number
    longitude: number
    currentEncarregadosString: string
    typeFormatted: string
    typeClass: string
    status: string
    statusFormatted: string
    state: string
    city: string
    hasCoordinates: boolean

    goals: Goal[]
    contracts: Contract[]
    allocations: Allocation[]
    accompaniments: Accompaniment[]
    currentEncarregados: Encarregado[]
    createdAt: Date
    updatedAt: Date
    oldestAllocationBeginning: Date
    newestAllocationEnd: Date

    public get startDate() : string {
        return this.oldestAllocationBeginning ? moment(this.oldestAllocationBeginning).format("DD/MM/YYYY") : null
    }
    
    public get endDate() : string {
        return this.newestAllocationEnd ? moment(this.newestAllocationEnd).format("DD/MM/YYYY") : null
    }
    
    public numberOfUnits(granularity: any, startDate: DateFormatted) : number {
        let selfStart = moment(this.newestAllocationEnd)
        let ganttStart = moment(startDate?.dateString, "DD/MM/YYYY")
        let start =  selfStart.isAfter(ganttStart) ? selfStart : ganttStart
        let numberOfUnits = 0;
        if (granularity == "days") {
            numberOfUnits = start
                .diff(moment(this.oldestAllocationBeginning), granularity)
        } else if (granularity == "months") {
            let years = start.year() - moment(this.oldestAllocationBeginning).year() 
            let months = start.month() - moment(this.oldestAllocationBeginning).month() 
            numberOfUnits = (12*years) + months
        } else if (granularity == "years") {
            numberOfUnits = start.year() - moment(this.oldestAllocationBeginning).year()
        } 
        return numberOfUnits + 1
    }

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
        newestAllocationEnd: e => this.parseDate(e),
        oldestAllocationBeginning: e => this.parseDate(e),
        allocations: e => Allocation.parse(e),
        contracts: e => Contract.parse(e),
        goals: e => Goal.parse(e),
        accompaniments: e => Accompaniment.parse(e),
        currentEncarregados: e => Encarregado.parse(e)
    }
}

export {
    Client
}