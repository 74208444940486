
<div fxFlex fxFill fxLayout="column">
    <div class="row" *ngIf="hasSearch && !searchField && !searchComponent">
      <div class="col text-right">
        <mat-form-field [floatLabel]="'never'">
          <input matInput placeholder="{{ searchFieldPlaceholder }}" #inputQuery>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div fxFlex fxLayout="column" class="p-24" fxLayoutAlign="center center"
    *ngIf="dataSource.isLoading | async">
      <mat-spinner style="display: inline-block" [diameter]="40" color="accent" ></mat-spinner>
      <h1> Carregando... </h1>
    </div>

    <div class="mat-white-bg">
        <table mat-table #table [dataSource]="dataSource" [@animateStagger]="{value:'50'}">
            <ng-content></ng-content>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <ng-container *ngIf="!shouldColorEvenRows">
              <tr mat-row *matRowDef="let row; columns: displayedColumns" [@animate]="{value:'*',params:{y:'100%'}}" matRipple (click)="onSelectedRowInternal(row)" ></tr>
            </ng-container>
            <ng-container *ngIf="shouldColorEvenRows">
              <tr mat-row
              *matRowDef="let row; columns: displayedColumns; let i = index"
              [ngClass]="{'even-row': isEvenRow(i), 'apply-hover': shouldHoverRows}"
              [@animate]="{value:'*',params:{y:'100%'}}"
              matRipple
              (click)="onSelectedRowInternal(row)"></tr>
            </ng-container>
            <ng-container *ngIf="showFooter">
              <tr style="font-size: 0.5rem;" mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
            </ng-container>
          </table>

      <div *ngIf="dataSource.isEmpty | async" class="no-records">
          Nenhum Item Encontrado
      </div>
    </div>
    <mat-paginator [pageSize]="perPage" [pageIndex]="page" [length]="dataSource.totalItems | async" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>