import { Decodable } from './_decodable';
import {environment} from "../../environments/environment";

class Device extends Decodable {
    id: number
    deviceId: string
    externalToken: string
    externalTokenType: string
    image: string
    platform: string
    version: number
    systemVersion: number
    model: string
    mobileBrand: string
    modelName: string
    language: string

    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
    
    public get imageUrl() : string {
        if(this.image) return this.image;
        return `${environment.storageUrl}/categories/default.png`
    }

    public get name() : string {
        if(this.modelName) return this.modelName
        if(this.mobileBrand && this.model) return `${this.mobileBrand} - ${this.model}`
        return `${this.platform} - ${this.systemVersion}`
    }
    
}

export {
    Device
}