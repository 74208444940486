import { Decodable } from './_decodable';

class Filter extends Decodable {
    name: string
    status: string

    constructor(name: string = "", status: string = "" ){
        super()
        this.name = name
        this.status = status
    }
}

export {
    Filter
}