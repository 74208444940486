<div class="avatar-wrapper" *ngIf="!showButton">
    <img (click)="fileInput.click()" [src]="_src" class="avatar m-0"/>
    <button *ngIf="editable" (click)="fileInput.click()" mat-icon-button class="mat-accent">
        <mat-icon>edit</mat-icon>
    </button>
</div>

<div fxLayout="column" fxLayoutAlign="center center" *ngIf="showButton" >
    <img (click)="fileInput.click()" [src]="_src" class="avatar m-0"/>
    <button *ngIf="editable" (click)="fileInput.click()" mat-raised-button class="mat-accent">
        <span>browse</span>
    </button>
</div>
<input #fileInput (change)="fileChange($event)" hidden type="file" name="image" [accept]="accept" >