import { Decodable } from './_decodable';
class CallResponse extends Decodable {
    id: number
    description: string
    created_at: Date
    updated_at: Date

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    CallResponse
}