import { OrganicMaterial } from 'app/entities';
import { Decodable } from './_decodable';
import {environment} from "../../environments/environment";
import { Tipo } from './tipo';
import { Product } from './product';
import { ContractProduct } from './contract-product';

class Contract extends Decodable {
    PROVIDER_IFB = 5
    PROVIDER_CLIENT = 4

    PAYMENT_TYPE_MONTHLY = 3
    PAYMENT_TYPE_ON_DELIVERY = 4
    
    id: number
    name: string
    code: string
    clienteId: number
    host: number
    food: number
    fuel: number
    paymentType: number

    equipamentTypes: Tipo[]
    products: Product[]
    contractProducts: ContractProduct[]
    organicMaterial: OrganicMaterial
    
    vigenciaInicio: Date
    vigenciaFim: Date
    createdAt: Date
    updatedAt: Date
    
    protected _map = {
        equipamentTypes: e => Tipo.parse(e),
        products: e => Product.parse(e),
        contractProducts: e => ContractProduct.parse(e),
        organicMaterial: e => OrganicMaterial.parse(e),
        vigenciaInicio: e => this.parseDate(e),
        vigenciaFim: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Contract
}