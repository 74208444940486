import { Decodable } from './_decodable';
class AccompanimentStone extends Decodable {
    id: number
    name: string
    stonePercentage: number
    teorP2o5: number
    stoneSupplierId: number
    stoneAmount: number
    isPositiveBalance: boolean
    deliveredTotal: number
    remainingToDeliver: number
    deliveredPercentage: number
    createdAt: Date
    updatedAt: Date
    
    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    AccompanimentStone
}