import { Equipamento } from 'app/entities';
import { EquipamentLessor } from 'app/entities';
import { Decodable } from './_decodable';

class ContractRentedEquipament extends Decodable {
    id: number
    isActive: boolean
    usageOverRentingPercent: number
    contractTitle: string

    equipamentLessor: EquipamentLessor
    equipamento: Equipamento
    vigenciaInicio: Date
    vigenciaFim: Date
    updatedAt: Date
    createdAt: Date

    protected _map = {
        vigenciaInicio: (e: any) => this.parseDate(e),
        vigenciaFim: (e: any) => this.parseDate(e),
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
        equipamentLessor: (e: any) => EquipamentLessor.parse(e),
        equipamento: (e: any) => Equipamento.parse(e),
    }
}

export {
    ContractRentedEquipament
}