import { TonsReaisPipe } from './tonsReais.pipe';
import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { TonsPipe } from './tons.pipe';
import { UniqueByPropertyPipe } from './uniqueByProperty.pipe';
import { ToFixedPipe } from './toFixed.pipe';
import { AbreviationPipe } from './abreviation.pipe';
import { barComponentnumberFormatPipePipe } from './barComponentnumberFormatPipe.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        TonsPipe,
        TonsReaisPipe,
        UniqueByPropertyPipe,
        barComponentnumberFormatPipePipe,
        ToFixedPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        AbreviationPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        TonsPipe,
        TonsReaisPipe,
        ToFixedPipe,
        UniqueByPropertyPipe,
        barComponentnumberFormatPipePipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        AbreviationPipe
    ]
})
export class FusePipesModule
{
}
