import { Decodable } from './_decodable';
import { Filter } from './filter';

class AppTable extends Decodable {
    page: number
    per_page: number

    filter: Filter

    protected _map = {
        filter: e => Filter.parse(e)
    }

    constructor(page: number = 0, per_page: number = 0, filter: Filter = new Filter()){
        super()
        this.page = page
        this.per_page = per_page
        this.filter = filter
    }

}

export {
    AppTable
}