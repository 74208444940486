import { Decodable } from './_decodable';
import {environment} from "../../environments/environment";

class InoculoDelivery extends Decodable {
    id: number
    bagsDelivered: number
    accompanimentId: number
    date: Date
    createdAt: Date
    updatedAt: Date
    
    protected _map = {
        date: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
    }
}

export {
    InoculoDelivery
}