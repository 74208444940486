import { ContractRentedEquipament } from 'app/entities';
import { EquipamentLessor } from 'app/entities';
import { Decodable } from './_decodable';
import { Tipo, Category } from '.';

class Equipamento extends Decodable {
    STATUS_AVAILABLE: number = 5
    STATUS_UNAVAILABLE: number = 6

    id: number
    equipamentLessorId: number
    name: string
    chassis: string
    status: number
    isLocatedClass: string
    isLocatedFormatted: string
    isLocated: boolean
    statusColor: string
    statusFormatted: string
    statusObservation: string
    location: string
    tipoId: number
    categoryId: number
    categoria: Category
    equipamentLessor: EquipamentLessor
    contractsRentedEquipament: ContractRentedEquipament[]
    tipo: Tipo
    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
        categoria: (e: any) => Category.parse(e),
        tipo: (e: any) => Tipo.parse(e),
        contractsRentedEquipament: (e: any) => ContractRentedEquipament.parse(e),
        equipamentLessor: (e: any) => EquipamentLessor.parse(e)
    }
}

export {
    Equipamento
}