import { Injectable } from '@angular/core';
import { ApiResponse, Call } from "app/entities";
import { ApiService } from "app/services/api.service";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CallService extends ApiService {

  findAll(params: any) {
    params = this.paramsFromObject(params);
    return this.httpService.get(`/v1/admin/calls`, params)
      .pipe(
        map(res => ApiResponse.parseWith(new Call, res) as ApiResponse<Call[]>)
      )
  }

  find(id: any) {
    return this.httpService.get(`/v1/admin/calls/${id}`)
      .pipe(
        map(res => Call.parse(res.data) as Call)
      )
  }

  store(data: any) {
    let formData = this.formDataFromObject(data)
    return this.httpService.post(`/v1/admin/calls`, formData)
      .pipe(
        map(res => Call.parse(res.data) as Call)
      )
  }

  update(id: number, data: any) {
    let formData = this.formDataFromObject(data)
    return this.httpService.post(`/v1/admin/calls/${id}`, formData)
      .pipe(
        map(res => Call.parse(res.data) as Call)
      )
  }

  destroy(id: number, data: any) {
    return this.httpService.delete(`/v1/admin/calls/${id}`, data)
  }

}
