import { Decodable } from './_decodable';
import { Encarregado } from './encarregado';
import { OrganicMaterial } from './organic-material';
import { Product } from './product';
import { StoneDelivery } from './stone-delivery';
import { Cycle } from './cycle';
import { Client } from './client';
import { Call } from './call';
import { Goal } from './goal';
import { InoculoDelivery } from './inoculo-delivery';
import { Contract } from './contract';
import { AccompanimentStone } from './accompaniment-stone';
import { Production } from './production';
import { Insumo } from './insumo';
import { Formulacao } from './formulacao';
import { ContractProduct } from './contract-product';

class Accompaniment extends Decodable {
    STATUS_PLANNED = 5
    STATUS_PROCESSING = 4
    STATUS_CONCLUDED = 6
    STATUS_EVALUATING = 7

    id: number
    contractId: number
    clienteId: number
    supervisorId: number
    prospectusId: number
    status: number
    phosphateStoneAmount: number
    phosphateStonePercentage: number
    productAmount: number
    stoneDeliveredAmount: number
    stoneToDeliver: number
    stockStoneAmount: number
    usedStoneAmount: number
    stoneAmount: number
    batidasRemaining: number
    stoneIsPositiveBalance: boolean
    inoculumIsPositiveBalance: boolean
    BatidasIsPositiveBalance: boolean
    days: number
    daysElapsed: number
    weeks: number
    weeksElapsed: number
    startMonth: string
    endMonth: string
    statusFormatted: string
    statusClass: string
    inoculumBagsSent: number
    productIsPositiveBalance: boolean
    inoculumBagsMissing: number
    inoculumTonsDone: number
    inoculumTonsMissing: number
    inoculumTotalCalculated: number
    inoculumTotalFulfilled: number
    inoculumWeeklyGoal: number
    inoculumWeeklyGoalDone: number
    stockInoculumBagAmount: number
    inoculumPlannedRhythm: number
    inoculumRealRhythm: number
    inoculumBagsPercentage: number
    inoculumTonsPercentage: number
    inoculationPercentage: number
    deadlinePercentage: number
    stoneDeliveredPercentage: number
    deliveredInoculumBagAmount: number
    inoculumBagsAmount: number
    totalFormulado: number
    inoculumPercentage: number

    calls: Call[]
    insumosUtilizados: Insumo[]
    cliente: Client
    contract: Contract
    cycle: Cycle
    goals: Goal[]
    encarregados: Encarregado[]
    organicMaterial: OrganicMaterial
    product: Product
    supervisor: Encarregado[]
    stonesDelivered: StoneDelivery[]
    stoneSuppliers: AccompanimentStone[]
    inoculumsDelivered: InoculoDelivery[]
    encarregadosHistory: Encarregado[]
    contractProducts: ContractProduct[]
    contractProduct: ContractProduct
    production: Production
    formulacoes: Formulacao[]
    updatedAt: Date
    createdAt: Date


    protected _map = {
        updatedAt: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
        calls: e => Call.parse(e),
        insumosUtilizados: e => Insumo.parse(e),
        contract: e => Contract.parse(e),
        cliente: e => Client.parse(e),
        supervisor: e => Encarregado.parse(e),
        encarregado: e => Encarregado.parse(e),
        stoneSuppliers: e => AccompanimentStone.parse(e),
        organicMaterial: e => OrganicMaterial.parse(e),
        inoculumsDelivered: e => InoculoDelivery.parse(e),
        encarregadosHistory: e => Encarregado.parse(e),
        product: e => Product.parse(e),
        stonesDelivered: e => StoneDelivery.parse(e),
        production: e => Production.parse(e),
        formulacoes: e => Formulacao.parse(e),
        contractProduct: e => ContractProduct.parse(e),
        contractProducts: e => ContractProduct.parse(e),
        goals: e => Goal.parse(e),
    }
}

export {
    Accompaniment
}