import { Decodable } from './_decodable';
class Production extends Decodable {
    id: number
    accompanimentId: number
    tonsInoculadas: number
    rochaUtilizada: number
    tonsFormuladas: number

    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Production
}