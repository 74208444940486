import { Decodable } from './_decodable';
import {environment} from "../../environments/environment";

class Machine extends Decodable {
    id: string
    name: string
    chassi: string
    brand: string
    type: string
    typeFormatted: string
    typeClass: string
    status: string
    statusFormatted: string
    
    created_at: Date
    updated_at: Date
    
    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Machine
}