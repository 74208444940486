import { Decodable } from './_decodable';

class AutocompleteData extends Decodable {
    id: number
    name: string
    status: number
    isCurrentCycle: boolean
}

export {
    AutocompleteData
}