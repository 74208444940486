import { Router } from '@angular/router';
import { fuseAnimations } from './../../../../@fuse/animations/index';
import { Call } from 'app/entities';
import { CallService } from './../../../main/pages/accompaniments/call.service';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class QuickPanelComponent
{
    calls: Call[] = [];

    constructor(
        private _callService: CallService,
        private _router: Router, 
    ) {
        this._callService.findAll({}).subscribe(res => {
            this.calls = res.data
        })

        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this._router.onSameUrlNavigation = 'reload';
    }

    navigateToCall(accompanimentId: number) {
        console.log("id para navegar: ",accompanimentId);
        this._router.navigate(['accompaniments', accompanimentId], {replaceUrl: true, queryParams: { selectedIndex: 2 } })
    }
}
