import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rating-bar',
  templateUrl: './rating-bar.component.html',
  styleUrls: ['./rating-bar.component.scss']
})
export class RatingBarComponent implements OnInit {

  @Input("rating") 
  public rating;

  @Input("comment") 
  public comment;
  
  public get rangeRating() : number[] {
    return new Array(5)
  }
  
  iconForIndex(index) {
    if(this.rating >= index+1) {
      return "star"
    } else if(this.rating > index && this.rating < index+1) {
      return "star_half"
    } else {
      return "star_border"
    }
  }
  
  constructor() { }

  ngOnInit() {
  }

}
