import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, distinctUntilChanged } from "rxjs/operators";
import { HttpService } from './http.service';
import { User } from '../entities/user';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject$ = new BehaviorSubject<User>(new User());
  public currentUser$ = this.currentUserSubject$.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject$ = new ReplaySubject<boolean>(1);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  public get currentUser(): User {
    return this.currentUserSubject$.value
  }

  public set currentUser(user: User) {
    if (user != null) {
      this.currentUserSubject$.next(user);
    } else {
      this.currentUserSubject$.next(new User());
    }
    this.isAuthenticatedSubject$.next(user != null)
  }

  constructor(private httpService: HttpService, private localStorageService: LocalStorageService) { }

  async populate() {
    try {
      let currentUserToken = this.localStorageService.getItem('currentUserToken');
      if (currentUserToken) {
        this.currentUser = await this.httpService.get('/v1/admin/login/token')
          .pipe(map(response => User.parse(response.data)))
          .toPromise();
      } else {
        this.currentUser = null
      }
    } catch (e) {
      this.currentUser = null
    }
  }

  login(credentials: any) {
    return this.httpService.post('/v1/admin/login', credentials)
      .pipe(
        map(response => User.parse(response.data)),
        tap(async (user: User) => {
          if (user && user.accessToken) {
            localStorage.setItem('currentUserToken', user.accessToken);
            this.currentUser = user
          }
        }));
  }

  forgotPassword(credentials: any) {
    return this.httpService.post('/v1/admin/password/email', credentials)
  }

  resetPassword(credentials: any) {
    return this.httpService.post('/v1/admin/password/reset', credentials)
  }

  logout() {

    return this.httpService.post('/v1/admin/logout')
      .pipe(
        tap(async () => {
          localStorage.removeItem('currentUserToken');
          this.currentUser = null
        }));
  }
}