import { Decodable } from './_decodable';
import {environment} from "../../environments/environment";
import { StoneSupplier } from './stone-supplier';

class StoneDelivery extends Decodable {
    id: number
    tons: number
    teorP2o5: number
    stoneSupplierId: number
    accompanimentId: number
    date: Date
    encarregadoId: number

    supplier: StoneSupplier
    createdAt: Date
    updatedAt: Date
    
    protected _map = {
        supplier: e => StoneSupplier.parse(e),
        date: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
    }
}

export {
    StoneDelivery
}