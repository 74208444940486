import { Decodable } from './_decodable';
import { Encarregado } from './encarregado';
import { OrganicMaterial } from './organic-material';
import { Product } from './product';
import { StoneDelivery } from './stone-delivery';
import { Cycle } from './cycle';
import { Client } from './client';
import { Call } from './call';
import { Goal } from './goal';
import { InoculoDelivery } from './inoculo-delivery';

class Consts extends Decodable {
    ACCOMPANIMENT_STATUS_PLANNED: 4
    ACCOMPANIMENT_STATUS_PROCESSING: 5
}

export {
    Consts
}