import { MachineOperator } from 'app/entities';
import { Decodable } from './_decodable';
import { Permission } from './permission';
import { Equipamento } from './equipamento';
import { Encarregado } from './encarregado';
import { Client } from '.';
import * as moment from 'moment';
import { GanttData } from './ganttData';
import { DateFormatted } from './date-formatted';
import { Assistant } from './assistant';
import { Driver } from './driver';

class Allocation extends Decodable implements GanttData  {
    id: number
    equipamento_id: number
    type: string
    cliente_id: number
    encarregado_id: number
    statusFormatted: string
    beginning: Date
    end: Date
    color: string
    equipamento: Equipamento
    encarregado: Encarregado
    operator: MachineOperator
    assistant: Assistant
    driver: Driver

    cliente: Client

    createdAt: Date
    updatedAt: Date

    public get startDate() : string {
        return this.beginning ? moment(this.beginning).format("DD/MM/YYYY") : null
    }
    
    public get endDate() : string {
        return this.end ? moment(this.end).format("DD/MM/YYYY") : null
    }
    
    public numberOfUnits(granularity: any) : number {
        let numberOfUnits = 0;
        if (granularity == "days") {
            numberOfUnits = moment(this.end, "YYYY/MM/DD")
            .diff(moment(this.beginning, "YYYY/MM/DD"), granularity)
        } else if (granularity == "months") {
            let years = moment(this.end).year() - moment(this.beginning).year() 
            let months = moment(this.end).month() - moment(this.beginning).month() 
            numberOfUnits = (12*years) + months
        } else if (granularity == "years") {
            numberOfUnits = moment(this.end, "YYYY/MM/DD").year() - moment(this.beginning, "YYYY/MM/DD").year()
        } 
        return numberOfUnits + 1
    }

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
        beginning: (e: any) => this.parseDate(e),
        end: (e: any) => this.parseDate(e),
        equipamento: (e: any) => Equipamento.parse(e),
        encarregado: (e: any) => Encarregado.parse(e),
        operator: (e: any) => MachineOperator.parse(e),
        assistant: (e: any) => Assistant.parse(e),
        driver: (e: any) => Driver.parse(e),
        cliente: (e: any) => Client.parse(e),
    }
}

export {
    Allocation
}