import { FuseNavigation } from "@fuse/types";
import { environment } from "environments/environment";

export const navigation: FuseNavigation[] = [
    {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: "dashboard",
        url: "/dashboard",
        roles: ["admin"],
        exactMatch: true,
    },
    {
        id: "plano-producao",
        title: "Plano de Produção",
        type: "item",
        icon: "svg:production_plan_white",
        url: "/planos-producao",
        roles: ["admin"],
    },
    {
        id: "accompaniments",
        title: "Acompanhamentos",
        type: "item",
        icon: "svg:accompaniment-icon",
        url: "/accompaniments",
        roles: ["admin"],
    },
    {
        id: "produtos-formulados",
        title: "Formulação",
        type: "item",
        icon: "svg:science",
        url: "/produtos-formulados",
        roles: ["admin"],
    },
    {
        id: "dashboard-amostras",
        title: "Amostras",
        type: "item",
        icon: "svg:lab_research",
        url: "/dashboard-amostras",
        roles: ["admin"],
    },
    {
        id: "pedclients",
        title: "Clientes",
        type: "item",
        icon: "people",
        url: "/clientes",
        roles: ["admin"],
    },
    {
        // api google maps AIzaSyCoO2RgOrdP8cDr9I2WZdt6gh6AmWJBuLg
        id: "mapa-clientes",
        title: "Mapa de Clientes",
        type: "item",
        icon: "public",
        url: "/mapa-clientes",
        roles: ["admin", "supplies"],
    },
    {
        id: "relatorios",
        title: "Relatórios",
        type: "collapsable",
        icon: "svg:pie_chart",
        roles: ["admin"],
        children: [
            {
                id: "dashboard-producao",
                title: "Toneladas Contratadas X Liberadas por contrato",
                type: "item",
                url: "/dashboard-producao",
                roles: ["admin"],
            },
        ],
    },
    {
        id: "allocations",
        title: "Alocações",
        type: "item",
        icon: "svg:allocation_icon",
        url: "/allocations",
        roles: ["admin", "supplies"],
    },
    {
        id: "equipaments",
        title: "Equipamentos",
        type: "collapsable",
        icon: "svg:equipament-icon",
        roles: ["admin", "supplies", "shipping"],
        children: [
            {
                id: "equipament",
                title: "Equipamentos",
                type: "item",
                url: "/equipament",
                roles: ["admin", "supplies", "shipping"],
                parentIcon: "svg:cash-multiple",
            },
            {
                id: "type",
                title: "Tipos",
                type: "item",
                url: "/types",
                roles: ["admin"],
                parentIcon: "svg:cash-multiple",
            },
            {
                id: "category",
                title: "Categorias",
                type: "item",
                url: "/categories",
                roles: ["admin"],
                parentIcon: "svg:cash-multiple",
            },
            {
                id: "equipament-lessor",
                title: "Locatários",
                type: "item",
                url: "/equipament-lessors",
                roles: ["admin"],
                parentIcon: "svg:cash-multiple",
            },
        ],
    },
    {
        id: "human_resources",
        title: "Recursos Humanos",
        type: "collapsable",
        icon: "svg:account_circle_24dp",
        roles: ["admin", "hr", "shipping"],
        children: [
            {
                id: "dashboard-rh",
                title: "RH Dashboard",
                type: "item",
                url: "/dashboard-rh",
                roles: ["admin", "hr", "shipping"],
                parentIcon: "svg:badge",
            },
            {
                id: "encarregados",
                title: "Encarregados",
                type: "item",
                url: "/encarregados",
                roles: ["admin", "hr", "shipping"],
                parentIcon: "svg:badge",
            },
            {
                id: "operadores",
                title: "Operadores de Máquina",
                type: "item",
                url: "/operadores",
                roles: ["admin", "hr", "shipping"],
                parentIcon: "svg:engineering",
            },
            {
                id: "motoristas",
                title: "Motoristas",
                type: "item",
                url: "/motoristas",
                roles: ["admin", "hr", "shipping"],
                parentIcon: "svg:engineering",
            },
            {
                id: "auxiliares",
                title: "Auxiliares",
                type: "item",
                url: "/auxiliares",
                roles: ["admin", "hr", "shipping"],
                parentIcon: "svg:engineering",
            },
        ],
    },
    {
        id: "configs",
        title: "Configurações",
        type: "collapsable",
        icon: "settings",
        roles: ["admin"],
        children: [
            {
                id: "organic-materials",
                title: "Matéria Orgânica",
                type: "item",
                icon: "svg:organic-material-icon",
                url: "/organic-materials",
                roles: ["admin"],
            },
            {
                id: "products",
                title: "Produtos",
                type: "item",
                icon: "svg:product-icon",
                url: "/products",
                roles: ["admin"],
            },
            {
                id: "cycles",
                title: "Ciclos",
                type: "item",
                icon: "svg:cycle-icon",
                url: "/cycles",
                roles: ["admin"],
            },
            {
                id: "suppliers",
                title: "Fornecedores de Rocha",
                type: "item",
                icon: "svg:local_shipping_current",
                url: "/suppliers",
                roles: ["admin"],
            },
            {
                id: "users",
                title: "Usuários",
                type: "item",
                icon: "svg:account-key-outline",
                url: "/users",
                roles: ["admin"],
            },
            {
                id: "prospectos",
                title: "Prospectos",
                type: "item",
                url: "/prospectos",
                roles: ["admin"],
                icon: "svg:no_account_24dp",
            },
            {
                id: "vehicles",
                title: "Veículos",
                type: "item",
                url: "/veiculos",
                roles: ["admin"],
                icon: "directions_car",
            },
            {
                id: "reasons",
                title: "Motivos",
                type: "item",
                url: "/motivos",
                roles: ["admin"],
                icon: "info_i",
            },
        ],
    },
    {
        id: "cicloverde",
        title: "Ciclo Verde PCP",
        type: "item",
        icon: "svg:cicloverdelogo",
        url: "https://cicloverde-pcp.grupoifb.com.br",
        externalUrl: true,
        openInNewTab: true,
        roles: ["admin"],
        exactMatch: true,
    },
];
