import { Decodable } from './_decodable';
class Category extends Decodable {
    id: number
    name: string
    statusFormatted: string
    
    createdAt: Date
    updatedAt: Date
    
    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Category
}