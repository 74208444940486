import { Pipe, PipeTransform } from '@angular/core';
import { Accompaniment } from 'app/entities';

@Pipe({name: 'uniqueByProperty'})
export class UniqueByPropertyPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param value
     * @param {number} month
     * @returns {any[]}
     */
    transform(value: any, property: string): number
    {
        return value.filter((accomp, i, arr) => arr.findIndex(t => t[property] === accomp[property]) === i)
    }
}
