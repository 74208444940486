import { Decodable } from './_decodable';

class Image extends Decodable {
    id: number
    url: string
    
    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
    }
}

export {
    Image
}