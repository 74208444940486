import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'abreviation'})
export class AbreviationPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} args
     * @returns {string}
     */
    transform(value: string, args: any[] = []): string
    {
        return value ? String(value).replace(/(horas)/g, (g) => `h`).replace(/(minutos)/g, (g) => `min`).replace(/(segundos)/g, (g) => `s`) : '';
    }
}
