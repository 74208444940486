import { Component, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const DAY_SELECTOR_FIELD_VALUE_ACCESSOR: any = {
  provide    : NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DaySelectorFieldComponent),
  multi      : true
};

@Component({
  selector: 'app-day-selector-field',
  templateUrl: './day-selector-field.component.html',
  styleUrls: ['./day-selector-field.component.scss'],
  providers: [DAY_SELECTOR_FIELD_VALUE_ACCESSOR]
})
export class DaySelectorFieldComponent implements ControlValueAccessor {

    // Color changed
    @Output()
    daysChanged: EventEmitter<any>;

    // Private
    private _days: any[];
    private _modelChange: (value: any) => void;
    private _modelTouched: (value: any) => void;

    buttonsMap = [
      {name: 'DOM'}, 
      {name: 'SEG'}, 
      {name: 'TER'}, 
      {name: 'QUA'},
      {name: 'QUI'},
      {name: 'SEX'},
      {name: 'SÁB'}
    ]

  constructor() { 

        // Set the private defaults
        this.daysChanged = new EventEmitter();
        this._days = [];
        this._modelChange = () => {
        };
        this._modelTouched = () => {
        };
  }

  toggleValue(v) {
    this._days = this._days.includes(v) ? this._days.filter(i => i !== v) : [ ...this._days, v ]
    this.updateSelectedValue()
  }

  itemClasses(v) {
    let c = []
    if(this._days.includes(v))
      c.push('selected')
    return c;
  }


  updateSelectedValue(): void
  {
    const value = !this._days || this._days.length == 0 ? null: this._days

        // Emit the color changed event
        this.daysChanged.emit(value);

        // Mark the model as touched
        this._modelTouched(value);

        // Update the model
        this._modelChange(value);
  }

  writeValue(obj: any): void {
    if(Array.isArray(obj) && typeof obj[0] == "number") {
      this._days = obj;
      this.updateSelectedValue()
    }
  }

  registerOnChange(fn: any): void {
    this._modelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._modelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    
  }

  ngOnInit(): void {
  }

}
