import { Encarregado } from 'app/entities';
import { Decodable } from './_decodable';
import { Permission } from './permission';

class Revision extends Decodable {
    id: number
    name: string
    encarregadoId: number
    
    encarregado: Encarregado
    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
    }
}

export {
    Revision
}