import { Decodable } from './_decodable';

class Reason extends Decodable {
    id: number
    description: string

    updatedAt: Date
    createdAt: Date
    deletedAt: Date

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Reason
}