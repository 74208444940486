import { Pipe, PipeTransform } from '@angular/core';
import { Accompaniment } from 'app/entities';

@Pipe({name: 'toFixed'})
export class ToFixedPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param value
     * @param {number} precision
     * @returns {number}
     */
    transform(value: number, precision: number): number
    {
        return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)
    }
}
