export const environment = {
    local       : false,
    production  : false,
    hmr         : false,

    appName: `IFB [[ DEVELOPMENT ]]`,

    wsPort:     2000,
    wsHost:     '',
    wsAppId:     "",
    apiHost:     'https://apipcp.grupoifb.com.br',
    baseUrl:     'https://apipcp.grupoifb.com.br',
    apiUrl:     'https://apipcp.grupoifb.com.br/api',
    storageUrl:  'https://apipcp.grupoifb.com.br/storage',
    googleMapsKey: 'AIzaSyDKay9VAjmvGXNNcNQt5oCNyrExq9z2Qc8',
    
};