import { Decodable } from "./_decodable";


class LinksMeta extends Decodable {
    first: string;
    last: string;
    prev: string;
    next: string;
}

class ResponseMeta extends Decodable {
    currentPage: number;
    from: number;
    lastPage: number; 
    path: string; 
    perPage: number;
    to: number;
    total: number;
    totalValue: number;
    footerData: any;
}

class ErrorEnvelope extends Decodable {
    code: number
    message: string
}

class ApiResponse<T> extends Decodable {

    data?: T;
    meta?: ResponseMeta
    links?: LinksMeta
    error?: ErrorEnvelope

    proto: typeof Decodable;
    
    static parseWith(proto: Decodable, obj: any) {
        let response = new ApiResponse(proto.constructor);
        response = super.parse(obj, response) as ApiResponse<any>
        return response
    }

    constructor(proto: any) {
        super()
        this.proto = proto
    }

    protected _map = {
        'data': (e) => this.proto.parse(e),
        'meta': (e) => ResponseMeta.parse(e),
        'links': (e) => LinksMeta.parse(e),
        'error': (e) => ErrorEnvelope.parse(e),
    }
}

export {
    LinksMeta,
    ResponseMeta, 
    ApiResponse
}