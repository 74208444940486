import * as moment from 'moment';
import { Accompaniment } from './accompaniment';
import { Allocation } from './allocation';
import { DateFormatted } from './date-formatted';
import { Encarregado } from './encarregado';
import { Goal } from './goal';
import { Decodable } from './_decodable';

class EquipamentLessor extends Decodable {
    id: number
    name: string
    cpfCnpj: string

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    EquipamentLessor
}