import { Vehicle, Encarregado } from 'app/entities';
import { Decodable } from './_decodable';
class EncarregadoVehicleHistory extends Decodable {
    id: number
    vehicleId: number
    encarregadoId: number

    vehicle: Vehicle
    Encarregado: Encarregado
    beginning: Date
    end: Date
    createdAt: Date
    updatedAt: Date

    protected _map = {
        beginning: e => this.parseDate(e),
        end: e => this.parseDate(e),
        vehicle: e => Vehicle.parse(e),
        encarregado: e => Encarregado.parse(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    EncarregadoVehicleHistory
}