import { Contract } from 'app/entities';
import { Decodable } from './_decodable';

class ContractReport extends Decodable {
    id: number
    contractId: string
    contract: Contract
    products: number[]
    valRelatorio: string
    pdfLink: string

    dataFim: Date
    dataInicio: Date
    createdAt: Date
    updatedAt: Date
    
    protected _map = {
        dataFim: e => this.parseDate(e),
        dataInicio: e => this.parseDate(e),    
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    ContractReport
}