import { Decodable } from './_decodable';

class StoneSupplier extends Decodable {
    id: number
    name: string
    stonePercentage: number

    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
    }
}

export {
    StoneSupplier
}