import { AfterViewInit, ContentChildren, ContentChild, Component, QueryList, ElementRef, AfterContentInit, OnInit, ViewChild, EventEmitter, Input, Output, ViewEncapsulation, OnDestroy, Optional } from '@angular/core';
import { TableDataSource, TableParams } from "./table.datasource";

import { debounceTime, distinctUntilChanged, tap, takeUntil, skip } from 'rxjs/operators';
import { fromEvent, merge, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTable, MatColumnDef } from '@angular/material/table';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations,
})
export class TableComponent<T> implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  private sort?: MatSort;

  @ViewChild(MatTable, {static: true}) table: MatTable<T>;

  @ViewChild('inputQuery', {static: true}) inputQuery?: ElementRef;

  @Input() dataSource?: TableDataSource<T>;
  @Output() onTableUpdate?= new EventEmitter<TableParams>();
  @Output() onSelectedRow?= new EventEmitter<T>();
  @Input() displayedColumns = [];
  @Input() searchFieldPlaceholder = "";
  @Input() searchFieldName = "q";
  @Input() pageSizeOptions = [50, 100, 200];
  @Input() showFooter = false

  @Input() shouldColorEvenRows: boolean = false;
  @Input() shouldHoverRows: boolean = false;

  @Input() searchField: ElementRef = null;

  @Input() searchComponent: SearchComponent = null;

  @Input() hasSearch = true

  @Input() perPage = 50
  @Input() page = 0

  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  private _unsubscribeAll: Subject<any>;
  constructor(@Optional() private _sort: MatSort) {
    this._unsubscribeAll = new Subject();
    this.sort = _sort
   }

  ngOnInit() {
    if (!this.dataSource) {
      this.dataSource = new TableDataSource<T>();
    }

    this.dataSource.isLoading.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((loading) => {
      window.dispatchEvent(new Event('resize'));
      if(this.searchComponent) {
        this.searchComponent.working = loading
      }
    })

    if(this.dataSource.params) {
      this.perPage = this.dataSource.params.per_page
      this.page = this.dataSource.params.page-1
    }
  }

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
  }

  ngAfterViewInit() {
    let inputQuery: any = this.searchField
    if(!inputQuery && this.inputQuery) {
      inputQuery = this.inputQuery.nativeElement
    }

    if(this.hasSearch && inputQuery) {
      fromEvent(inputQuery, 'keyup').pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(350),
        distinctUntilChanged(),
        tap(() => {
          this.loadTable(true)
        })
      )
        .subscribe();
    }

    if(this.searchComponent) {
      this.searchComponent.formChange.pipe(
        takeUntil(this._unsubscribeAll),
        skip(1),
        debounceTime(350),
        distinctUntilChanged(),
        tap(() => {
          this.loadTable(true)
        })
      )
        .subscribe();

        if (this.dataSource && this.dataSource.params && this.dataSource.params.filter) {
          setTimeout(() => {
            this.searchComponent.patchForm(this.dataSource.params.filter)
          })
        }
    }

    if(this.sort) {
      let skipSort = 0
      if (this.dataSource && this.dataSource.params && this.dataSource.params.sort) {
        let sort = this.dataSource.params.sort
        let direction = sort ? sort.startsWith("-") ? "desc": "asc": ""
        sort = sort && direction == "desc"? sort.substring(1): sort

        if(sort && direction) {
          setTimeout(() => {
            let _sort = <MatSortable>{
              id: sort,
              start: direction,
              disableClear: false
            }

            this.sort.sort(_sort)
            console.log(_sort);
          })
          skipSort = 1
        }
      }

      this.sort.sortChange.pipe(
        skip(skipSort),
        takeUntil(this._unsubscribeAll),
        distinctUntilChanged(),
        tap((e) => {
          console.log(e);
          this.loadTable(true)
        })
      ).subscribe()
    }

    this.paginator.page.pipe(
      takeUntil(this._unsubscribeAll),
      distinctUntilChanged(),
        tap(() => {
          this.loadTable(false)
        })
      )
      .subscribe();
  }

  loadTable(resetPage: boolean) {
    if(resetPage) {
      this.paginator.pageIndex = 0
    }

    let params: TableParams = {
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize
    };
    if(this.searchComponent) {
      params.filter = this.searchComponent.value
    } else {
      let inputQuery: any = this.searchField
      if(!inputQuery && this.inputQuery) {
        inputQuery = this.inputQuery.nativeElement
      }

      if (this.hasSearch && inputQuery) {
        params.filter = {}
        params.filter[this.searchFieldName] = inputQuery.value;
      }
    }

    if (this.sort && this.sort.direction) {
      let sort = this.sort.direction == 'asc' ? this.sort.active : `-${this.sort.active}`;
      params.sort = sort;
    }

    if (this.onTableUpdate) {
      setTimeout(() => {
        this.onTableUpdate.emit(params)
      })
    }
  }

  onSelectedRowInternal(row) {
    if(this.onSelectedRow) {
      this.onSelectedRow.emit(row)
    }
  }

  ngOnDestroy(): void {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  isEvenRow(index: number): boolean {
    return index % 2 == 0;
  }
}
