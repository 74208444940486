<div fxLayout="column" fxFlex fusePerfectScrollbar>
    <ng-container>
        <mat-divider cdkFocusRegionEnd></mat-divider>

        <mat-list>
            <h3 matSubheader>
                <span>Observações</span>
            </h3>

            <mat-list-item (click)="navigateToCall(call?.accompanimentId)" class="clickable" *ngFor="let call of calls">
                <div matLine class="font-small-strong">{{call?.accompaniment?.contract?.name}} {{call?.accompaniment?.cliente?.name}}</div>
                <div matLine class="paragraph">
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" 
                    class="secondary-text w-100-p">
                        <p class="w-70-p ellipsis">{{call?.description}}</p> 
                        <span class="text-boxed " ngClass="{{ call.statusClass }}"
                        [@animate]="{value:'*',params:{delay:'100ms',x:'25px'}}">
                            {{ call?.statusFormatted }}
                        </span>
                    </div>
                </div>
                <h3 matLine class="font-small-hours" >{{call?.timeElapsed}}</h3>
            </mat-list-item>
        </mat-list>
    </ng-container>
</div>