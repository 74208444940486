import { EchoConfig } from 'ngx-laravel-echo';
import { environment } from 'environments/environment';

const token = localStorage.getItem("currentUserToken")

const echoConfig: EchoConfig = {
    userModel: 'users',
    notificationNamespace: 'App\\Notifications',
    options: {
        broadcaster: 'pusher', 
        key: environment.wsAppId,
        wsHost: environment.wsHost,
        authEndpoint: environment.baseUrl + '/broadcasting/auth',
        host: environment.apiHost,
        wsPort: environment.wsPort,
        disableStats: true,
        namespace: '',
        httpHost: environment.wsHost,
        httpPort: environment.wsPort,
        statsHost: environment.wsHost,
        auth: {
            headers: {
                Authorization: token ? "Bearer " + token: null
            }
        }
    }
};

export {
    echoConfig
}