// import 'prismjs/prism';
// import 'prismjs/components/prism-bash';
// import 'prismjs/components/prism-c';
// import 'prismjs/components/prism-cpp';
// import 'prismjs/components/prism-csharp';
// import 'prismjs/components/prism-css';
// import 'prismjs/components/prism-diff';
// import 'prismjs/components/prism-markup';
// import 'prismjs/components/prism-markup-templating';
// import 'prismjs/components/prism-java';
// import 'prismjs/components/prism-javascript';
// import 'prismjs/components/prism-json';
// import 'prismjs/components/prism-perl';
// import 'prismjs/components/prism-php';
// import 'prismjs/components/prism-python';
// import 'prismjs/components/prism-sass';
// import 'prismjs/components/prism-scss';
// import 'prismjs/components/prism-typescript';
