import { Injectable } from '@angular/core';

import { HttpParams } from '@angular/common/http';
import { HttpService } from './http.service';

import * as Moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    protected httpService: HttpService) { }

  public paramsFromObject(obj: any, key: string = ""): HttpParams {
    let params = new HttpParams();

    if (obj == null) { return params; }

    params = this.populateHttpParams(params, key, obj);

    return params
  }

  private populateHttpParams(params: HttpParams, key: string, value: any) {
    if (Array.isArray(value)) {
      value.forEach((_value, index) => {
        let _key = key + '[' + index + ']';
        params = this.populateHttpParams(params, _key, _value)
      })
    } else if (value instanceof Date) {
      params = params.append(key, ApiService.dateTimeToString(value));
    } else if (value instanceof Moment) {
      params = params.append(key, ApiService.momentTimeToString(value as Moment.Moment));
    } else if (value instanceof Object) {
      Object.keys(value).forEach((_key) => {
        let _value: any = value[_key];
        _key = key ? key + '[' + _key + ']' : _key;
        params = this.populateHttpParams(params, _key, _value)
      })
    } else {
      params = params.append(key, (!value ? "" : String(value)));
    }
    return params;
  }

  public formDataFromObject(model: any, form: FormData = null, namespace = ''): FormData {

    let formData = form || new FormData();
    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || model[propertyName] == undefined) continue;
      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;

      if (model[propertyName] instanceof Blob) {
        let blob: any = model[propertyName];
        model[propertyName] = new File([blob], blob.name || "blob");
      }
      if (model[propertyName] instanceof Date) {
        formData.append(formKey, ApiService.dateTimeToString(model[propertyName]));
      } else if (model[propertyName] instanceof Moment) {
        formData.append(formKey, ApiService.momentTimeToString(model[propertyName]));
      }
      else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element: any, index: any) => {
          if (typeof element != 'object' || element instanceof File || element instanceof Blob || element instanceof Date || element instanceof Moment) {
            if (element instanceof Blob && !(element instanceof File)) {
              formData.append(`${formKey}[]`, new File([element], "blob"));
            } else if (element instanceof Date) {
              formData.append(`${formKey}[]`, ApiService.dateTimeToString(element));
            } else if (element instanceof Moment) {
              formData.append(`${formKey}[]`, ApiService.momentTimeToString(element as Moment.Moment));
            } else {
              formData.append(`${formKey}[]`, element);
            }
          } else {
            const tempFormKey = `${formKey}[${index}]`;
            this.formDataFromObject(element, formData, tempFormKey);
          }
        });
      }
      else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
        this.formDataFromObject(model[propertyName], formData, formKey);
      }
      else {
        formData.append(formKey, model[propertyName]);
      }
    }
    return formData;
  }

  static dateTimeToString(date: Date) {
    try {
      return date.toISOString();
    } catch (e) { }
  }

  static momentTimeToString(date: Moment.Moment) {
    try {
      return date.format("YYYY-MM-DD[T]HH:mm:ss");
    } catch (e) { }
  }
}
