import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AlertService } from 'app/services/alert.service';

@Component({
  selector: 'app-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss']
})
export class ImageFieldComponent implements OnInit {

  @Input() accept = ['image/png', 'image/jpeg']
  @Input() editable = true
  @Input() showButton = false
  @Output() imageSelected = new EventEmitter();

  _src: string = 'assets/images/avatars/profile.jpg';
  @Input() set src(value: any) {
    if(value) {
      this._src = value
    } else {
      this._src = '/assets/images/avatars/profile.jpg'
    }
  }

  constructor(
    private _alertService: AlertService) { }

  ngOnInit() {
  }

  fileChange(event: any) {
    if (!this.editable) return;
    
    let file, img;

    if ((file = event.target.files[0]) && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      var reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = (_event) => { 
        this.src = reader.result
      }

      this.imageSelected.emit(file)
      
    } else {
      this.imageSelected.emit()
      this._alertService.error("Formato de imagem não suportado. Somente 'jpg' e 'png' são permitidos.")
    }
  }
}
