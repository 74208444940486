import { Decodable } from './_decodable';
class OrganicMaterial extends Decodable {
    id: number
    name: string
    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    OrganicMaterial
}