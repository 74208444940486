import { Comment, Encarregado, MachineOperator, Equipamento } from 'app/entities';
import { User } from './user';
import { Decodable } from './_decodable';

class GeneralItems extends Decodable {
    encarregados: Encarregado[]
    operadores: MachineOperator[]
    equipamentos: Equipamento[]

    protected _map = {
        encarregados: e => Encarregado.parse(e),
        operadores: e => MachineOperator.parse(e),
        equipamentos: e => Equipamento.parse(e),
    }
}

export {
    GeneralItems
}