import { User } from './user';
import { Decodable } from './_decodable';

class Comment extends Decodable {
    id: number
    description: string
    callId: number

    user: User
    updatedAt: Date
    createdAt: Date

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
        user: e => User.parse(e),
    }
}

export {
    Comment
}