import { Decodable } from './_decodable';
import { Permission } from './permission';

class Formulacao extends Decodable {
    insumoId: number
    accompanimentId: string
    quantidade: number

    protected _map = {
    }
}

export {
    Formulacao
}