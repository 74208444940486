import { Pipe, PipeTransform } from '@angular/core';
import { Accompaniment } from 'app/entities';

@Pipe({name: 'tons'})
export class TonsPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param value
     * @param {number} month
     * @returns {number}
     */
    transform(value: any, month: number): number
    {
        return value.goals.find(goal => goal.month == month)?.tons ?? 0
    }
}
