<button mat-icon-button class="arrow" (click)="previousDate()">
    <mat-icon>chevron_left</mat-icon>
</button>

<div class="title">
    {{ currentDate | date:'dd/MM/yyyy'}}
</div>

<button mat-icon-button class="arrow" (click)="nextDate()">
    <mat-icon>chevron_right</mat-icon>
</button>