import { Decodable } from './_decodable';
import { Permission } from './permission';

class Tipo extends Decodable {
    id: number
    name: string
    quantity?:number
    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
        permissions: (e: any) => Permission.parse(e)
    }
}

export {
    Tipo
}