import { Decodable } from './_decodable';
import { Insumo } from './insumo';
import { Permission } from './permission';
import { Service } from './service';

class ContractProduct extends Decodable {
    id: number
    productId: number
    quantity: number
    quantidadeFormulada: number
    formulacaoLiberada: boolean

    insumos: Insumo[]
    services: Service[]
    createdAt: Date
    updatedAt: Date

    protected _map = {
        createdAt: (e: any) => this.parseDate(e),
        updatedAt: (e: any) => this.parseDate(e),
        insumos: e => Insumo.parse(e),
        services: e => Service.parse(e),
        permissions: (e: any) => Permission.parse(e)
    }
}

export {
    ContractProduct
}