<div class="nav" [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">

        <ng-container *ngFor="let item of navigation">

            <fuse-nav-vertical-group *ngIf="isVisible(item, 'group')" [item]="item"></fuse-nav-vertical-group>
            <fuse-nav-vertical-collapsable *ngIf="isVisible(item, 'collapsable')"
                                           [item]="item"></fuse-nav-vertical-collapsable>
            <fuse-nav-vertical-item *ngIf="isVisible(item, 'item')" [item]="item"></fuse-nav-vertical-item>

        </ng-container>

    </ng-container>
    <!-- / Vertical Navigation Layout -->

    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">

        <ng-container *ngFor="let item of navigation">

            <fuse-nav-horizontal-collapsable *ngIf="isVisible(item, 'group')" [item]="item"></fuse-nav-horizontal-collapsable>
            <fuse-nav-horizontal-collapsable *ngIf="isVisible(item, 'collapsable')"
                                             [item]="item"></fuse-nav-horizontal-collapsable>
            <fuse-nav-horizontal-item *ngIf="isVisible(item, 'item')" [item]="item"></fuse-nav-horizontal-item>

        </ng-container>

    </ng-container>
    <!-- / Horizontal Navigation Layout -->

</div>
