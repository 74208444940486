import { Decodable } from './_decodable';
import {environment} from "../../environments/environment";
import { Permission } from './permission';

class UserSimple extends Decodable {
    id: number
    name: string
    image: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
}
class User extends UserSimple {
    email: string
    supplierId: number
    role: string
    roleNames: string[]
    roleNamesFormatted: string[]
    permissions: Permission[]
    accessToken: string
    receiveNewContractsEmail: boolean
    status: string
    statusFormatted: string
    statusClass: string
    emailVerifiedAt: Date

    public get isSuperAdmin() : boolean {
        return this.isRole("superadmin")
    }

    public get isAdmin() : boolean {
        return this.isRole("admin")
    }

    public get isSupplier() : boolean {
        return this.isRole("supplier") || this.supplierId != null
    }

    public get isBuyerSupplier() : boolean {
        return this.isRole("buyer_supplier")
    }

    public get isCommercial() : boolean {
        return this.isRole("commercial")
    }

    public get isOperator() : boolean {
        return this.isRole("operator")
    }

    public get isManager() : boolean {
        return this.isRole("manager")
    }

    public get isSupport() : boolean {
        return this.isRole("support")
    }

    
    public get permissionNames() : string[] {
        if(this.permissions && this.permissions.length > 0) {
            return this.permissions.map(p => p.name)
        }
        return []
    }

    public isRole(role: any) : boolean {
        return this.roleNames && this.roleNames.indexOf(role) > -1
    }

    public can(permissions: string[]): boolean {        
        if(!permissions || !this.permissionNames) return false
        const hasPermission = permissions.every(p => this.permissionNames.includes(p))
        return !!hasPermission
    }
    
    protected _map = {
        permissions: e => Permission.parse(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
        emailVerifiedAt: e => this.parseDate(e),
    }
    
    public get avatar() : string {
        if(this.image) return this.image;
        return `${environment.storageUrl}/users/default.png`
    }
}

export {
    User, UserSimple
}