export class Decodable {

    constructor() {
        this.afterDecode(this)
    }

    static parse(obj: any, casted: any = null) {
        if(obj === null) return null;
        
        if(!casted)
            casted = new this()
        else
            casted = Object.assign(new casted.constructor, casted);

        try {
            if (typeof obj == "string" && obj.length > 1) {
                obj = JSON.parse(obj)
            }
        } catch(e){
            console.error(e, obj)
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => {
                return this.parse(item, casted)
            })
        } else if (obj != null && typeof obj == "object") {
            
            for (var prop in obj) {
                let camelCased = prop.replace(/_([a-z-0-9])/g, g => g[1].toUpperCase());

                if (casted['_map'] && casted['_map'][camelCased]) {
                    casted[camelCased] = casted['_map'][camelCased](obj[prop]);
                } else {
                    casted[camelCased] = obj[prop];
                }
            }

            if (casted['afterDecode']) {
                casted['afterDecode'](casted)
            }

            return casted
        }
        return new this()
    }

    public get _() {
        var underlined = {}
        for (const key in this) {
            if (this.hasOwnProperty(key)) {
                const element = this[key];
                const _key = key.replace(/([A-Z])/g, g => `_${g.toLowerCase()}`)                
                if(element instanceof Decodable) {
                    underlined[_key] = element._
                } else if (element && Array.isArray(element) && element[0] instanceof Decodable) {
                    underlined[_key] = element.map(e => e._)
                } else {
                    underlined[_key] = element
                }
            }
        }

        return underlined
    }

    mock(): any {
        Object.keys(this).forEach(prop => {
            this[prop] = prop;
        });

        return this
    }

    parseDate(str): Date {
        try {
            if(typeof str == "string") {
                if(str.length == 10) {
                    str = `${str} 00:00:00`
                }
                str = str.replace(/ /g, "T")
                let date = new Date(str);
                return date
            }
        } catch {}
        return null
    }

    toString(): string {
        return JSON.stringify(this)
    }

    protected afterDecode(instance) {
        if(instance['_map'])
            delete instance['_map']

        if(instance['Map'])
            delete instance['Map']

        if (instance['afterDecode']) {
            delete instance['afterDecode']
        }
    }
}